import React, { useEffect } from "react";
import { Box, Text, Button, SimpleGrid, Image } from "@chakra-ui/react";
import { useParams, useLocation, Link } from "react-router-dom";
import axios from "axios";
import { DecryptED } from "../../config/Decrypt";
import { live_Url, token } from "../../config";
import ReusableComponents from "./Resusable";
import { getSchoolNameStart } from "../../redux/actions/common.action";
import { useDispatch, useSelector } from "react-redux";

const AppIcons = ({ mob }) => {
  return (
    <SimpleGrid
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Image
        w={15}
        h={15}
        src="https://commonfiles.prisms.in/commonfiles/986960_whatsapp_icon.png"
      />
      <Link
        to={`https://wa.me/${mob}`}
        style={{
          color: "blue",
          marginLeft: "5px",
        }}
      >
        {mob}
      </Link>{" "}
    </SimpleGrid>
  );
};

const Appstore = () => {

  const IosNewApp = 'https://r.prisms.in/prismsoneios';
  const AndroidNewApp = 'https://r.prisms.in/parent';
  const dispatch = useDispatch();
  const { schName } = useSelector((state) => state.searchStudent);

  const [turl, setTurl] = React.useState();
  const [appUrl, setAppUrl] = React.useState();
  const [logo, setLogo] = React.useState();
  const [sname, setSname] = React.useState();
  const [appLink, setApplink] = React.useState();
  const { sid } = useParams();
  const [URL, setURL] = React.useState();
  const location = useLocation();

  useEffect(() => {
    const getAppStoreUrl = async () => {
      try {
        await axios({
          method: "POST",
          url: `${live_Url}/api/tscommon/app/url`,
          data: { sid: DecryptED(sid) },
          headers: {
            Authorization: token,
          },
        }).then((res) => {
          setAppUrl(res.data.data);
          setSname(res.data.res2[0].name);
          setLogo(
            res.data.res2[0].school_logo.replace(
              "https://s3.amazonaws.com/topschool-attachments",
              "https://files.prisms.in"
            )
          );
        });
      } catch (error) {
        console.log(error);
      }
    };

    getAppStoreUrl();

    const getType = () => {
      if (location.pathname.includes("/parent/")) {
        return "parent";
      } else if (location.pathname.includes("/teacher/")) {
        return "teacher";
      } else if (location.pathname.includes("/admin/")) {
        return "admin";
      }
    };
    setTurl(getType());

    dispatch(getSchoolNameStart(DecryptED(sid)));
  }, [location, sid, dispatch]);

  useEffect(() => {
    const getUrl = () => {
      if (turl === "parent") {
        return appUrl && appUrl.length > 0
          ? appUrl[0].apk_parent_app
          : "Parent Link Not Found";
      } else if (turl === "admin") {
        return appUrl && appUrl.length > 0
          ? appUrl[0].apk_admin_app
          : "Teacher Link Not Found";
      } else if (turl === "teacher") {
        return appUrl && appUrl.length > 0
          ? appUrl[0].apk_teacher_app
          : "Admin Link Not Found";
      }
      return "Invalid Type";
    };
    let t = getUrl();
    setApplink(t);
  }, [appUrl, turl]);

  useEffect(() => {
    let url = new URLSearchParams(window.location.search).get("url");
    setURL(url);
  }, []);

  return (
    <>
      <Box
        width={"full"}
        height={"100vh"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        backgroundColor={"#fff"}
      >
        <Image mt={2} p={2} boxSize="200px" src={logo} alt={sname} />
        {appLink && appLink.length > 0 ? (
          <SimpleGrid column={{ md: "2", sm: "1" }}>
            <a href={AndroidNewApp} download>
              <Button
                colorScheme="blue"
                variant="solid"
                aria-label="Download APK"
                mt={4}
                p={2}
                fontFamily={"Poppins"}
                style={{
                  width: "220px",
                }}
              >
             { turl === "teacher" ? "Download New Android App" :"Download New Android App" }
              </Button>
            </a>
            <a href={appLink} download>
              <Button
                colorScheme="blue"
                variant="solid"
                aria-label="Download Old APK"
                mt={4}
                p={2}
                fontFamily={"Poppins"}
                style={{
                  width: "220px",
                }}
              >
             { turl === "teacher" ? "Download Old Andriod APK" :"Download Old Andriod APK" }
              </Button>
            </a>
            <a href={IosNewApp} download>
              <Button
                colorScheme="blue"
                variant="solid"
                aria-label="Download APK"
                mt={4}
                p={2}
                fontFamily={"Poppins"}
                style={{
                  width: "220px",
                }}
              >
                Download IOS App
              </Button>
            </a>
            {/* https://schoolurl.prisms.in/pay */}
          </SimpleGrid>
        ) : (
          <>
            <Text
              fontSize="xl"
              fontFamily={"Poppins"}
              fontWeight={"bold"}
              mt={3}
            >
              APP is not available
            </Text>
          </>
        )}
        {turl === "parent" ? (
          <a href={`https://${ URL ? URL : schName}.prisms.in/pay`}>
            <Button
              mt={3}
              size={"md"}
              textAlign={"center"}
              fontFamily={"Poppins"}
              style={{
                width: "220px",
              }}
            >
              Pay Fees Online
            </Button>
          </a>
        ) : null}

        <SimpleGrid
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          w={"full"}
          mt={5}
        >
          <Text textAlign={"center"} fontFamily={"Poppins"} fontSize={13}>
            In case of any issues with the installation, please WhatsApp the
            screenshot of the error to{" "}
            <span style={{ display: "inline-flex", alignItems: "center" }}>
              <AppIcons mob={7666171221} />
            </span>
          </Text>
        </SimpleGrid>

        {/* इंस्टॉलेशनमध्ये काही समस्या असल्यास, कृपया त्रुटीचा स्क्रीनशॉट */}
        <SimpleGrid
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          w={"full"}
          h={10}
          mt={5}
        >
          <Text textAlign={"center"} fontFamily={"Poppins"} fontSize={13}>
            इंस्टॉलेशनमध्ये काही समस्या असल्यास, कृपया त्रुटीचा स्क्रीनशॉट
            WhatsApp number{" "}
            <span
              style={{
                display: "inline-flex",
                marginTop: "2px",
                alignItems: "center",
              }}
            >
              <AppIcons mob={7666171221} />
            </span>{" "}
            वर पाठवा
          </Text>
        </SimpleGrid>

        {turl === "parent" ? (
          <SimpleGrid
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            w={"full"}
            mt={5}
          >
            <Text textAlign={"center"} fontFamily={"Poppins"} fontSize={13}>
              To get login & app details on WhatsApp, send <b>hi</b> to
              <span
                style={{
                  display: "inline-flex",
                  marginTop: "2px",
                  alignItems: "center",
                }}
              >
                <AppIcons mob={7709000288} />
              </span>
              from your registered number.
            </Text>
          </SimpleGrid>
        ) : null}

        <SimpleGrid
          w={"full"}
          mt={10}
          mb={10}
          h={10}
          backgroundColor={"blue.700"}
        >
          <Text
            position={"relative"}
            textColor={"white"}
            backgroundColor={"blue.700"}
            textAlign={"center"}
            fontFamily={"monospace"}
            fontSize={23}
          >
            Steps to install
          </Text>
        </SimpleGrid>
        <ReusableComponents
          Mtitle={"Enable Installing Unknown Apps"}
          title={`Navigate to your phone’s Settings menu -> Security and privacy `}
          step={1}
          avatar={"https://commonfiles.prisms.in/commonfiles/security_apk.jpg"}
        />
        <ReusableComponents
          Mtitle={"Install App"}
          title={``}
          step={2}
          avatar={"https://commonfiles.prisms.in/commonfiles/download_apk.jpg"}
        />
      </Box>
    </>
  );
};

export default Appstore;
